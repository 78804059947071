import { isFeatureTogglesOn } from '@glow/common'
import { batch } from 'react-redux'
import { AnyAction, Middleware } from 'redux'

/**
 * Schedules actions with { meta: { raf: true } } to be dispatched inside a rAF loop
 * frame.  Makes `dispatch` return a function to remove the action from the queue in
 * this case. Modified to dispatch all actions in a batch.
 * Based on example from: https://redux.js.org/advanced/middleware
 */
export const rafScheduler: Middleware = () => (next) => {
  let queuedActions: unknown[] = []
  let frame: NodeJS.Timeout | number | null = null

  function loop() {
    frame = null
    try {
      if (queuedActions.length) {
        if (isFeatureTogglesOn(['RAF_NO_BATCH'])) {
          batch(() => queuedActions.forEach(next))
        } else {
          queuedActions.forEach(next)
        }

        queuedActions = []
      }
    } finally {
      maybeRaf()
    }
  }

  function maybeRaf() {
    if (isFeatureTogglesOn(['SET_TIMEOUT_RAF']) && queuedActions.length && !frame) {
      frame = setTimeout(loop, 1000)
    } else if (queuedActions.length && !frame) {
      frame = requestAnimationFrame(loop)
    }
  }

  return (action: unknown) => {
    const anyAction = action as AnyAction
    if (!anyAction.meta || !anyAction.meta.raf) {
      return next(action)
    }

    queuedActions.push(action)
    maybeRaf()

    return function cancel() {
      queuedActions = queuedActions.filter((a) => a !== action)
    }
  }
}
